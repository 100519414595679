import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";

const predefinedColors = [
    "#E57373", // Soft Red
    "#64B5F6", // Soft Blue
    "#81C784", // Soft Green
    "#FFD54F", // Soft Yellow
    "#BA68C8", // Soft Purple
    "#4FC3F7", // Soft Cyan
    "#FF8A65", // Soft Orange
    "#A1887F", // Soft Brown
    "#90A4AE", // Soft Gray-Blue
    "#F06292"  // Soft Pink
];

const getAvatarStyle = (username) => {
    if (!username) return {};
    const length = username.length;
    const colorIndex = length > 9 ? length % 10 : length;
    return {
        backgroundColor: predefinedColors[colorIndex],

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1rem",
        fontWeight: "bold",
        textTransform: "uppercase",
    };
};

const HomeHeader = ({ user }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="border-b border-gray-700 bg-gray-900 text-white">
            <div className="container mx-auto px-5">
                <header className="flex justify-between items-center py-4">
                    {/* Logo */}
                    <img
                        src="/assets/images/logo.svg"
                        alt="CoreContest Logo"
                        className="w-36"
                    />
                    {/* User Menu */}
                    <div className="relative">
                        <div
                            className="flex items-center cursor-pointer"
                            onClick={toggleMenu}
                        >
                            <div
                                className="w-10 h-10 rounded-full overflow-hidden flex justify-center items-center"
                                style={user?.avatar ? {} : getAvatarStyle(user?.username)}
                            >
                                {user?.avatar ? (
                                    <img
                                        src={user.avatar}
                                        alt="User Avatar"
                                        className="w-full h-full object-cover"
                                    />
                                ) : (
                                    user?.username?.substring(0, 2)
                                )}
                            </div>
                        </div>
                        {menuOpen && (
                            <div className="absolute right-0 mt-2 w-72 bg-white text-black border border-gray-200 rounded shadow-lg z-10">
                                {/* User Info */}
                                <div className="p-4 border-b border-gray-300">
                                    <div className="flex items-center">
                                        <div
                                            className="w-12 h-12 rounded-full overflow-hidden flex justify-center items-center mr-4"
                                            style={user?.avatar ? {} : getAvatarStyle(user?.username)}
                                        >
                                            {user?.avatar ? (
                                                <img
                                                    src={user.avatar}
                                                    alt="User Avatar"
                                                    className="w-full h-full object-cover"
                                                />
                                            ) : (
                                                user?.username?.substring(0, 2)
                                            )}
                                        </div>
                                        <div>
                                            <p className="font-bold text-lg">{user?.username}</p>
                                            {user?.fullName && (<p className="font-bold text-normal">{user?.fullName}</p>)}
                                            <p className="text-sm text-gray-500">{user?.email}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Privacy Policy */}
                                <Link
                                    to="/privacy-policy"
                                    className="block px-4 py-2 text-sm hover:bg-gray-100"
                                >
                                    Privacy Policy
                                </Link>
                                {/* Sign Out */}
                                <button
                                    onClick={() => {
                                        // Handle sign out logic here
                                    }}
                                    className="block w-full text-left px-4 py-2 text-sm flex items-center text-red-600 hover:bg-gray-100"
                                >
                                    <LogoutOutlined className="mr-2" />
                                    Sign Out
                                </button>
                            </div>
                        )}
                    </div>
                </header>
            </div>
        </div>
    );
};

export default HomeHeader;