import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import {
    HomeOutlined,
    UserOutlined,
    LockOutlined,
    CreditCardOutlined,
    InfoCircleOutlined, MenuOutlined, ShopOutlined, GiftOutlined, LineChartOutlined
} from '@ant-design/icons';
import HomeHeader from '../../components/HomeHeader';

const SidebarLayout = () => {
    const location = useLocation();
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const user = {
        avatar: '',
        username: 'mofoluwasoasdassfds',
        fullName: "Foluso Olateju",
        email: "folateju483@gmail.com"
    };

    // Define routes where the sidebar should be visible
    const sidebarRoutes = ['/home', '/personal-info', '/security','/security/change-password','/security/manage-2fa', '/payments', '/about','/store','/leaderboard','/achievements'];

    const menuItems = [
        { path: '/home', label: 'Home', icon: <HomeOutlined /> },
        { path: '/personal-info', label: 'Personal Info', icon: <UserOutlined /> }, //name change, image
        { path: '/achievements', label: 'Achievements', icon: <GiftOutlined /> },
        { path: '/leaderboard', label: 'LeaderBoard', icon: <LineChartOutlined /> },
        { path: '/payments', label: 'Payments', icon: <CreditCardOutlined /> }, //invoices, purchases, payment method
        { path: '/store', label: 'Store', icon: <ShopOutlined /> }, //Store, redeem points
        { path: '/security', label: 'Security', icon: <LockOutlined /> }, //password, 2fa
        { path: '/account-privacy', label: 'Account Privacy', icon: <InfoCircleOutlined /> }, //privacy policy and Account Closure
    ];

    const showSidebar = sidebarRoutes.includes(location.pathname);

    if (showSidebar) {
        return (
            <div className="flex flex-col min-h-screen">
                {/* Horizontal Navbar */}
                <HomeHeader user={user} />

                {/* Layout */}
                <div className="flex flex-1">
                    {/* Sidebar */}
                    <aside
                        className={`w-64 bg-white fixed lg:relative z-20 h-full shadow-lg transition-transform transform ${
                            isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
                        } lg:translate-x-0 lg:block`}
                    >
                        <nav className="mt-9">
                            {menuItems.map((item) => (
                                <Link
                                    key={item.path}
                                    to={item.path}
                                    className={`flex items-center px-5 py-3 my-1 text-gray-700 rounded-r-3xl ${
                                        location.pathname === item.path
                                            ? 'bg-blue-100 text-blue-600 font-bold'
                                            : 'hover:bg-gray-200'
                                    }`}
                                    onClick={() => setSidebarOpen(false)} // Close sidebar on mobile when a link is clicked
                                >
                                    <span className="mr-4">{item.icon}</span>
                                    {item.label}
                                </Link>
                            ))}
                            <img alt="Ambition" src="/assets/images/ambition.jpg"/>
                        </nav>
                    </aside>

                    {/* Main Content */}
                    <main
                        className={`flex-1 p-9 transition-all duration-300 ${
                            isSidebarOpen ? 'lg:ml-64' : 'ml-0'
                        }`}

                    >
                        {/* Mobile Sidebar Toggle */}
                        <button
                            className="lg:hidden fixed top-16 right-1 bg-gray-800 text-white p-3 rounded-md z-30"
                            onClick={() => setSidebarOpen(!isSidebarOpen)}
                        >
                            {isSidebarOpen ? 'Close Menu' : 'Menu'} <MenuOutlined />
                        </button>

                        <Outlet />
                    </main>
                </div>

                {/* Footer */}
                <footer className="bg-gray-800 text-white py-4 text-center">
                    © 2024 CoreContest. All rights reserved.
                </footer>
            </div>
        );
    }

    return (
        <div>
            <Outlet />
        </div>
    );
};

export default SidebarLayout;